// Used for just the navbar's nested row for now
.flex-1-100 {
  flex: 1 100%;
}

.documents-list .record-view-only {
  display: none;
}

.text-align-start {
  text-align: start;
}

