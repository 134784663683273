.external-links {
  list-style-type: none;
  padding-left: 0;
}

.external-links-psu-collections {
  display: inline-block;
  list-style-type: none;
  padding-left: 0;
}

.btn.toggle-external-links.collapsed::before {
  content: 'View More';
}

.btn.toggle-external-links::before {
  content: 'View Less';
}

