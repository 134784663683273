@include media-breakpoint-down(sm) {

  .seconday-actions * {
    font-size: 12px;
  }

  .page-links {
    margin-top: 4px;
  }

}

.record-actions label {
  margin-bottom: 0;
}
