.announcement {
  border-bottom: 3px solid $secondary;

  @include media-breakpoint-down(sm) {
    display: none;
  }

  a {
    color: $black;
    text-decoration: underline;
  }
}
