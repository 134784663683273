// scss-lint:disable QualifyingElement
article.document {
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}
// scss-lint:enable QualifyingElement

.documents-list .document-metadata {
  padding-left: 0;

  li {
    list-style: none;
  }
}

.documents-list .document .document-thumbnail {
  float: none;
  margin-bottom: 1em;
  padding-left: 0;
}
