.metadata {
  &-availability {
    border-top: 1px solid $gray-400;
  }

  &-secondary {
    border-top: 1px solid $gray-400;

    ul {
      padding-left: 18px;
    }
  }
}
