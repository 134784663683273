// overriding "blacklight-marc": "https://github.com/projectblacklight/blacklight-marc.git#v6.2.0",

// border between items on marc view
$marc-item-separator: 1px solid $text-muted !default;
// Temporary patch around bootstrap 4 compatibility for librarian view
$padding-xs-horizontal: 1ch !default;
// https://github.com/projectblacklight/demo.projectblacklight.org/pull/10/files
$nav-link-padding: $nav-link-padding-y $nav-link-padding-x;

.marc-view {
  text-align: left;

  .field {
    border-bottom: $marc-item-separator;
    clear: both;
  }

  .ind1,
  .ind2 {
    display: inline;
    padding: 0 $padding-xs-horizontal;
  }

  .subfields {
    padding-left: 5em;
  }

  .tag-ind {
    float: left;
  }
}

// refworks uses a form; we need to give it some additional padding to make it look like a link
.refworks-form .btn {
  padding: $nav-link-padding;
}
