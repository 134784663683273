// Theme colors
$psu-dark-blue: #1f427c;
$psu-blue: #0071bc;
$light-blue: #6888c2;
$link-color: $psu-blue;
$grayish: #e6e6e6;
$shadow: rgba(0, 0, 0, .57);
$off-white: #fefefe;
$nearly-black: #0a0f15;

// Bootstrap overrides (pre-bootsrap load, meaning before bootstrap vars are set)
//  Fonts
$headings-font-family: "Roboto Slab", Georgia, sans-serif;
$font-family-sans-serif: "Open Sans", Arial, serif;

//  Color overrides
$theme-colors: (
        "dark": $psu-dark-blue,
        "light": #f2f2f2,
);

//  Navbar
$navbar-brand-padding-y: 0;

//  Facet heading background color
$card-cap-bg: $grayish;

//  Heading font size
//   Please note, $font-size-base is defined in _variables.scss in Twitter Bootstrap. It is defined
//   here as well because it is required for proper heading font size changes. It is the same value as
//   $font-size-base in _variables.scss.
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

//   Heading sizes, using scales (see https://www.modularscale.com)
$major-third: 1.25;
$major-second: 1.125;
$just-right: 1.19;
$ratio: $just-right;
$h6-font-size: $font-size-base !default;
$h5-font-size: $h6-font-size * $ratio !default;
$h4-font-size: $h5-font-size * $ratio !default;
$h3-font-size: $h4-font-size * $ratio !default;
$h2-font-size: $h3-font-size * $ratio !default;
$h1-font-size: $h2-font-size * $ratio !default;

$mobile-ratio: 1.08;
$h6-mobile-font-size: $font-size-base !default;
$h5-mobile-font-size: $h6-mobile-font-size * $mobile-ratio !default;
$h4-mobile-font-size: $h5-mobile-font-size * $mobile-ratio !default;
$h3-mobile-font-size: $h4-mobile-font-size * $mobile-ratio !default;
$h2-mobile-font-size: $h3-mobile-font-size * $mobile-ratio !default;
$h1-mobile-font-size: $h2-mobile-font-size * $mobile-ratio !default;
