footer {
  background: $nearly-black;
  color: $grayish;
  padding-top: 20px;

  a {
    color: $light-blue;
  }

  h2 {
    color: $grayish;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }

  p,
  li {
    font-size: 12px;
    line-height: 1.7;
  }

  i {
    margin-right: 5px;
  }
}

.footer {
  padding-top: 34px;

  &__logo {
    @include make-col-ready();
    @include make-col(6);

    img {
      max-height: 40px;
    }
  }

  &__boiler {
    @include make-col-ready();
    @include make-col(12);
    margin-top: 5px;
  }

  &__meta {
    @include make-col-ready();
    @include make-col(12);

    ul {
      @include make-col-ready();
      @include make-col(6);
      list-style: none;
      margin-left: 0;
      padding-left: 0;
    }
  }

  &__social {
    @include make-col-ready();
    @include make-col(6);

    ul {
      list-style: none;
      padding-left: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .footer {
    &__logo {
      @include make-col(2);
    }

    &__boiler {
      @include make-col(4);

    }

    &__meta {
      @include make-col(4);
    }

    &__social {
      @include make-col(2);
    }
  }
}


