.sidenav {
  .navbar-toggler {
    border-color: $navbar-light-toggler-border-color;
    color: $navbar-light-active-color;

    @include hover-focus {
      color: $navbar-light-active-color;
    }
  }

  .navbar-toggler-icon {
    background-image: $navbar-light-toggler-icon-bg;
  }
}

.facets-toggleable {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-up($next) {

        // scss-lint:disable ImportantRule
        .facets-collapse {
          display: block !important;
          width: 100%;
        }
        // scss-lint:enable ImportantRule

        .navbar-toggler {
          display: none;
        }
      }
    }
  }
}

.facets-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}

.facets-heading {
  @extend .h4;
  line-height: inherit;
}

.facet-limit {
  margin-bottom: $spacer;

  .card-body {
    padding: $spacer;
  }
}

.facet-values {
  display: table;
  table-layout: fixed;
  width: 100%;
  margin-bottom: 0;

  li {
    display: table-row;
    font-size: 14px;
    line-height: 1.4;
  }

  .remove {
    color: $text-muted;
    font-weight: bold;
    padding-left: $spacer / 2;

    &:hover {
      color: theme-color("danger");
      text-decoration: none;
    }
  }

  @mixin hyphens-auto {
    overflow-wrap: break-word;
    hyphens: auto;
  }

  .facet-label {
    display: table-cell;
    padding-bottom: $spacer / 2;
    @include hyphens-auto;
  }

  .facet-count {
    display: table-cell;
    vertical-align: top;
    text-align: right;
    width: 5em;
  }
}

.more_facets {
  font-size: 14px;
}

.facet-extended-list {
  .sort-options {
    text-align: right;
  }

  .prev-next-links {
    float: left;
  }
}

.facet-field-heading {
  @extend .h6;
  /* This prevents the contained stretch link from covering the panel body */
  position: relative;

  a {
    color: inherit;
  }

}

// Sidenav
.facet-pagination {
  @extend .clearfix;

  &.top {
    padding: $modal-inner-padding;
  }
}

/* style for pivot facet's nested list */

.pivot-facet {
  @extend .list-unstyled;
  @extend .py-1;
  @extend .px-4;
}

.facet-leaf-node {
  margin-left: 1rem;
  padding-right: 1rem;
  margin-top: -1.5rem;
}

.facet-toggle-handle {
  font-family: "Font Awesome 5 Free";
  font-size: 13px;
  margin: -10px 0 0 -5px;
  padding: 0;

  &.collapsed {
    .show { display: block; }
    .hide { display: none; }
  }

  .show { display: none; }
  .hide { display: block; }
}
